import * as Sentry from '@sentry/react';

import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

// Custom hook designed to log errors to Sentry, included on Error fallback component
// This only works with thrown error responses in loaders/actions
// For manual logging (ie. handled/soft error) - see `utils/sentryCaptureException`
const useSentryCaptureException = () => {
  const routeError: any = useRouteError();

  useEffect(() => {
    // Normal unhandled exceptions get logged automatically by Sentry
    // This handles custom thrown error responses thrown from loaders/actions
    // "Soft" errors that are not explicitly thrown by loaders/actions are logged elsewhere via Sentry.captureException
    if (routeError && isRouteErrorResponse(routeError)) {
      // Skip logging 404 errors
      if (routeError.status === 404) return;

      let errorMessage = 'Unknown Error';
      let extraData = {};
      if (routeError.data) {
        errorMessage = `${routeError.data.errorLoc}: Error on Route: ${routeError.data.route} - Step: ${routeError.data.step}`;
        extraData = routeError.data.extra ?? {};
      }
      Sentry.captureException(new Error(errorMessage), { level: 'fatal', extra: extraData });
    }
  }, [routeError]);

  return null;
};

export default useSentryCaptureException;
