import DateCalendar from './DateCalendar';
import QuoteFlexCalendar from './QuoteFlexCalendar';

export default (theme) => ({
  styleOverrides: {
    body: {
      ...QuoteFlexCalendar(theme),
      ...DateCalendar(theme),
    },
    ul: {
      paddingLeft: theme.spacing(3.5),
    },
  },
});
