// #region Inactive icons
const AtticInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/attic.svg`;
const BonusInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/bonus.svg`;
const ClosetInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/closet.svg`;
const DiningRoomInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/dining_room.svg`;
const GarageInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/garage.svg`;
const GuestInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/guest_bedroom.svg`;
const GymInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/gym.svg`;
const KidsBedroomInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/kids_bedroom.svg`;
const KitchenInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/kitchen.svg`;
const LaundryInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/laundry.svg`;
const LivingRoomInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/living_room.svg`;
const NurseryInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/nursery.svg`;
const OfficeInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/office.svg`;
const PatioInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/patio.svg`;
const PrimaryBedroomInactiveIcon = `${process.env.PUBLIC_URL}/images/inventory/primary_bedroom.svg`;
// #endregion

// #region Active icons
const AtticActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/attic_active.svg`;
const BonusActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/bonus_active.svg`;
const ClosetActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/closet_active.svg`;
const DiningRoomActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/dining_room_active.svg`;
const GarageActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/garage_active.svg`;
const GuestActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/guest_bedroom_active.svg`;
const GymActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/gym_active.svg`;
const KidsBedroomActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/kids_bedroom_active.svg`;
const KitchenActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/kitchen_active.svg`;
const LaundryActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/laundry_active.svg`;
const LivingRoomActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/living_room_active.svg`;
const NurseryActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/nursery_active.svg`;
const OfficeActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/office_active.svg`;
const PatioActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/patio_active.svg`;
const PrimaryBedroomActiveIcon = `${process.env.PUBLIC_URL}/images/inventory/primary_bedroom_active.svg`;
// #endregion

const INVENTORY_ROOMS = [
  {
    title: 'Living room/den',
    iconAlt: 'Living room/den icon',
    inactiveIcon: LivingRoomInactiveIcon,
    activeIcon: LivingRoomActiveIcon,
    key: 'living-room',
  },
  {
    title: 'Primary bedroom',
    iconAlt: 'Primary bedroom icon',
    inactiveIcon: PrimaryBedroomInactiveIcon,
    activeIcon: PrimaryBedroomActiveIcon,
    key: 'primary-bedroom',
  },
  {
    title: 'Guest bedroom',
    iconAlt: 'Guest bedroom icon',
    inactiveIcon: GuestInactiveIcon,
    activeIcon: GuestActiveIcon,
    key: 'guest-bedroom',
  },
  {
    title: 'Nursery',
    iconAlt: 'Nursery icon',
    inactiveIcon: NurseryInactiveIcon,
    activeIcon: NurseryActiveIcon,
    key: 'nursery',
  },
  {
    title: 'Office',
    iconAlt: 'Office icon',
    inactiveIcon: OfficeInactiveIcon,
    activeIcon: OfficeActiveIcon,
    key: 'office',
  },
  {
    title: 'Bonus',
    iconAlt: 'Bonus icon',
    inactiveIcon: BonusInactiveIcon,
    activeIcon: BonusActiveIcon,
    key: 'bonus-room',
  },
  {
    title: 'Kids bedroom',
    iconAlt: 'Kids bedroom icon',
    inactiveIcon: KidsBedroomInactiveIcon,
    activeIcon: KidsBedroomActiveIcon,
    key: 'kids-bedroom',
  },
  {
    title: 'Garage Spaces',
    iconAlt: 'Garage icon',
    inactiveIcon: GarageInactiveIcon,
    activeIcon: GarageActiveIcon,
    key: 'garage',
  },
  {
    title: 'Attic/loft',
    iconAlt: 'Attic icon',
    inactiveIcon: AtticInactiveIcon,
    activeIcon: AtticActiveIcon,
    key: 'attic',
  },
  {
    title: 'Storage Closets',
    iconAlt: 'Closet icon',
    inactiveIcon: ClosetInactiveIcon,
    activeIcon: ClosetActiveIcon,
    key: 'storage-closet',
  },
  {
    title: 'Patio',
    iconAlt: 'Patio icon',
    inactiveIcon: PatioInactiveIcon,
    activeIcon: PatioActiveIcon,
    key: 'patio',
  },
  {
    title: 'Gym',
    iconAlt: 'Gym icon',
    inactiveIcon: GymInactiveIcon,
    activeIcon: GymActiveIcon,
    key: 'gym',
  },
  {
    title: 'Kitchen',
    iconAlt: 'Kitchen icon',
    inactiveIcon: KitchenInactiveIcon,
    activeIcon: KitchenActiveIcon,
    key: 'kitchen',
  },
  {
    title: 'Dining room',
    iconAlt: 'Dining room icon',
    inactiveIcon: DiningRoomInactiveIcon,
    activeIcon: DiningRoomActiveIcon,
    key: 'dining-room',
  },
  {
    title: 'Laundry',
    iconAlt: 'Laundry icon',
    inactiveIcon: LaundryInactiveIcon,
    activeIcon: LaundryActiveIcon,
    key: 'laundry',
  },
];

export const INITIAL_INVENTORY_ROOMS = INVENTORY_ROOMS.reduce((prev, room) => ({ ...prev, [room.key]: 0 }), {});

export default INVENTORY_ROOMS;
