import { useState } from 'react';

const useLocalStorage = (key: string, initialValue: any = '') => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined' || !window.localStorage) return initialValue;
    const savedItem = localStorage.getItem(key);
    return savedItem ? JSON.parse(savedItem) : initialValue;
  });

  const setValue = (newValue: any) => {
    setStoredValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
