const SPECIAL_INVENTORY_ITEMS = [
  {
    title: 'Large safe',
    key: 'large-safe',
  },
  {
    title: 'Dish washer',
    key: 'dishwasher',
  },
  {
    title: 'Washer/Dryer',
    key: 'washer-dryer',
  },
  {
    title: 'Large freezer',
    key: 'large-freezer',
  },
  {
    title: 'Refrigerator',
    key: 'refrigerator',
  },
  {
    title: 'Upright Piano',
    key: 'piano',
    tooltip: 'For grand or baby grand pianos we recommend hiring a specialty piano mover.',
  },
  {
    title: 'Oven/Stove',
    key: 'oven-stove',
  },
  {
    title: 'Riding lawn mower',
    key: 'riding-lawn-mower',
  },
  {
    title: 'Large armoire',
    key: 'large-armoire',
  },
];

export const INITIAL_SPECIAL_INVENTORY_ITEMS = SPECIAL_INVENTORY_ITEMS.reduce(
  (prev, item) => ({ ...prev, [item.key]: 0 }),
  {},
);

export default SPECIAL_INVENTORY_ITEMS;
