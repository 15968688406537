import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography, styled, useTheme } from '@mui/material';

import useMarketingHeader from '../../../../../hooks/useMarketingHeader';
import formatPhoneNumber from '../../../../../utils/formatPhoneNumber';

const HamburgerItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 56,
  '&:hover': {
    background: theme.palette.grey[50],
    '& p': {
      color: theme.palette.primary.main,
    },
  },
}));

const HamburgerLink = styled(Typography)(({ theme }) => ({
  variant: 'h4',
  component: 'p',
  marginLeft: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  fontFamily: theme.typography.body2.fontFamily,
  fontWeight: 400,
  fontSize: '1.5625rem',
  color: theme.palette.grey[800],
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
  },
}));

const HamburgerItems = () => {
  const [{ columns, link, phoneNumber }]: any[] = useMarketingHeader();
  const theme = useTheme();

  const subLinkFontProps = {
    paddingTop: theme.spacing(1.7),
    paddingLeft: theme.spacing(5),
    fontFamily: theme.typography.body2.fontFamily,
    fontWeight: 400,
    fontSize: '1.1rem',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
    },
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" pt={2}>
      <Box pt={{ xs: 8, md: 11 }}>
        <>
          {columns?.map((column) => {
            if (column.links?.length) {
              return (
                <Box key={`accordion-${column.title}`}>
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMore color="primary" fontSize="large" />}
                      sx={{
                        padding: theme.spacing(0, 4, 0, 0),
                        '&:hover': {
                          background: theme.palette.grey[50],
                          '& a': {
                            color: theme.palette.primary.main,
                          },
                        },
                        [theme.breakpoints.up('md')]: {
                          padding: theme.spacing(0, 2, 0, 0),
                        },
                        '&.Mui-expanded': {
                          background: theme.palette.grey[50],
                        },
                      }}
                    >
                      <Link color="grey.800" href={column.href} underline="none">
                        <HamburgerLink>{column.title}</HamburgerLink>
                      </Link>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <Box display="flex" width="100%" flexDirection="column" px={{ xs: 1, md: 0 }}>
                        {column.links.map((columnLink) => (
                          <Box
                            key={`hamburger-sublink-${columnLink.label}`}
                            height={52}
                            sx={{ '&:hover': { background: theme.palette.grey[50] } }}
                          >
                            <Link href={columnLink.href} underline="none" color="grey.800">
                              <Typography variant="h4" component="p" sx={{ ...subLinkFontProps }}>
                                {columnLink.label}
                              </Typography>
                            </Link>
                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            }
            return (
              <Link href={column.href} underline="none" key={`link-${column.label}`} color="grey.800">
                <HamburgerItemContainer>
                  <Box>
                    <HamburgerLink>{column.label}</HamburgerLink>
                  </Box>
                </HamburgerItemContainer>
              </Link>
            );
          })}
          {phoneNumber ? (
            <Link href={`tel:${phoneNumber}`} underline="none">
              <HamburgerItemContainer>
                <Box>
                  <HamburgerLink>{formatPhoneNumber(phoneNumber)}</HamburgerLink>
                </Box>
              </HamburgerItemContainer>
            </Link>
          ) : null}
          {link ? (
            <Link href={link.href} underline="none">
              <HamburgerItemContainer>
                <Box>
                  <HamburgerLink>{link.label}</HamburgerLink>
                </Box>
              </HamburgerItemContainer>
            </Link>
          ) : null}
        </>
      </Box>
    </Box>
  );
};

export default HamburgerItems;
