import { useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import cmsGQLClient from '../../modules/Hygraph';

const GET_HEADER = gql`
  query {
    header(where: { id: "ckqseyoaogan80f77nkmnrngs" }) {
      columns {
        ... on Link {
          label
          href
        }
        ... on NavigationGroup {
          id
          title
          links {
            label
            href
          }
        }
      }
      link {
        href
        label
      }
      logo {
        alt
        href
        url
      }
      phoneNumber
    }
  }
`;

export default () => {
  const { data, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['header'],
    queryFn: async () => cmsGQLClient.request<{ header: any }>(GET_HEADER),
  });

  return [data?.header ?? { logo: null }, { isLoading }];
};
