import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

const TrustBanner = () => {
  const theme = useTheme();

  const badges = [
    {
      icon: {
        id: '6TykeNVERK88y5eYTpXE',
        alt: 'Lock',
      },
      text: 'Secure Checkout',
    },
    {
      icon: {
        id: 'OVgPAK90R6WB7rkzZ8N7',
        alt: 'Calendar',
      },
      text: 'Flexible Reservation',
    },
    {
      icon: {
        id: '1r4Ehz7WSk6qYVgraGje',
        alt: 'Star',
      },
      text: 'Trusted Reviews',
    },
  ];

  return (
    <Box height={50} width="100%" padding={2}>
      <Box display="flex" justifyContent="space-around" margin="0 auto" width="100%" maxWidth={550}>
        {badges?.map(({ icon: { id, alt }, text }) => (
          <Box key={text} display="flex" alignItems="center" justifyContent="center">
            <img src={`https://media.graphcms.com/${id}`} alt={alt} />
            <Typography
              variant="body3"
              sx={{
                width: 'min-content',
                fontSize: '0.875rem',
                marginLeft: 1,
                textAlign: 'left',
                lineHeight: 1.2,
                color: theme.palette.grey[500],
                [theme.breakpoints.up('sm')]: {
                  width: 'max-content',
                  fontSize: theme.typography.h4.fontSize,
                },
              }}
            >
              {text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TrustBanner;
