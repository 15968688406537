import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { arrayOf, node, oneOfType, shape } from 'prop-types';
import React, { useContext } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';

import AnalyticsProvider, { AnalyticsContext } from './context/Analytics';
import CustomerRequestProvider from './context/CustomerRequest';
import Theme from './theme';

const GrowthBookWrapper = ({ children }) => {
  const { track } = useContext(AnalyticsContext);

  const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      track('Experiment Viewed', {
        'Experiment Id': experiment.key,
        'Variant Id': result.variationId,
      });
    },
  });

  fetch(process.env.REACT_APP_GROWTHBOOK_API_ENDPOINT)
    .then((res) => res.json())
    .then((parsed) => {
      growthbook.setFeatures(parsed.features);
    });

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
};

GrowthBookWrapper.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

const App = ({ queryClient }) => (
  <Theme>
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider enabled={process.env.NODE_ENV === 'production'}>
        <CustomerRequestProvider>
          <CookiesProvider>
            <GrowthBookWrapper>
              <Outlet />
            </GrowthBookWrapper>
          </CookiesProvider>
        </CustomerRequestProvider>
      </AnalyticsProvider>
    </QueryClientProvider>
  </Theme>
);

App.propTypes = {
  queryClient: shape({}).isRequired,
};

export default App;
