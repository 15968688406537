import { useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import cmsGQLClient from '../../modules/Hygraph';

// Actual query key to Hygraph is `partner` but using `affiliate` terminology to better match new distinction between partners and affiliates
const GET_AFFILIATE = gql`
  query GetAffiliates($affiliate: String) {
    partner(where: { identifier: $affiliate }) {
      id
      identifier
      name
      affiliateAccountId
      desktopSvg {
        id
        url
        alt
      }
      mobileSvg {
        id
        url
        alt
      }
    }
  }
`;

export default ({ affiliate }: { affiliate: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['affiliate', affiliate],
    queryFn: async () => cmsGQLClient.request<{ partner: any }>(GET_AFFILIATE, { affiliate }),
    enabled: !!affiliate,
  });

  return [data?.partner ?? null, { isLoading }];
};
