import _ from 'lodash';
import { element } from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';
import { INITIAL_CUSTOMER_INFO } from '../../constants/booking';
import useLocalStorage from '../../utils/useLocalStorage';
import objectDiff from '../../utils/useLocalStorage/objectDiff';
import useSessionStorage from '../../utils/useSessionStorage';

type RequestLocation = {
  line_1: string;
  line_2: string;
  city: string;
  state: string;
  country: string;
  marketCode: string;
  postal_code: string;
  property_type: string;
  propertyInfo: Record<string, any>;
};

type Customer = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

type Inventory = {
  rooms: Record<string, number>;
  additionalItems: Record<string, number>;
};

type CustomerRequest = {
  customer: Customer;
  locations: RequestLocation[];
  date: string;
  time: number;
  truck: boolean;
  inventory: Inventory;
  gbIdentity: string;
  inventoryModalAcknowledged: boolean;
  leadId: string;
  affiliateAccountId: string;
  selectedTemplate: string;
  marketingSource?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    clickid?: string;
    gclid?: string;
    msclkid?: string;
  };
};

type CustomerContext = {
  value: CustomerRequest;
  customerInfo: CustomerRequest;
  setValue: React.Dispatch<CustomerRequest>;
  setCustomerInfo: React.Dispatch<CustomerRequest>;
};

export const CustomerRequestContext = createContext<CustomerContext>({
  value: INITIAL_CUSTOMER_INFO,
  setValue: null,
  customerInfo: INITIAL_CUSTOMER_INFO,
  setCustomerInfo: null,
});

// Goal of this provider is to always hold the initial information the customer entered
// ie. requested services, basic information, locations - no matter what the quote
// We always want to retain this data for logic and determination
const CustomerRequestProvider = ({ children }) => {
  const [savedCustomerRequest, setSavedCustomerRequest] = useLocalStorage('customerRequest');
  const [savedCustomerInfo, setSavedCustomerInfo] = useSessionStorage<CustomerRequest>(
    'customerInfo',
    INITIAL_CUSTOMER_INFO,
  );

  const [value, setValue] = useState(savedCustomerRequest);

  const data = useMemo(() => {
    // Only set local storage if state value is not empty object and if different from currently saved
    if (!_.isEmpty(value)) {
      const diff = objectDiff(value, savedCustomerRequest);
      if (!_.isEmpty(diff)) {
        setSavedCustomerRequest(value);
      }
    }
    return { value, setValue, customerInfo: savedCustomerInfo, setCustomerInfo: setSavedCustomerInfo };
  }, [value, savedCustomerInfo]);

  return <CustomerRequestContext.Provider value={data}>{children}</CustomerRequestContext.Provider>;
};

CustomerRequestProvider.propTypes = {
  children: element,
};

CustomerRequestProvider.defaultProps = {
  children: null,
};

export default CustomerRequestProvider;
